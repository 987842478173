<template>
  <div class="AnNiuDaoHang" @mouseleave="DHyichu()">
    <!-- 左边的导航 -->
    <div class="DaDaoHan">
      <div class="DaDaoHanBox">
        <!-- vr -->
        <div>
          <img @click="VR()" src="../../assets/Public/Navigation_Buttons/VRYL.png" alt="" />
        </div>
        <!-- 数字文创 -->
        <div>
          <img @click="ShuZiWC()" src="../../assets/Public/Navigation_Buttons/SZWC.png" alt="" />
        </div>
        <!-- 三维古镇 -->
        <div>
          <img @click="SanWeiGZ()" src="../../assets/Public/Navigation_Buttons/SWGZ.png" alt="" />
        </div>
        <!-- 畅玩古镇 -->
        <div>
          <img @click="CWGuZhen()" src="../../assets/Public/Navigation_Buttons/CWGZ.png" alt="" />
        </div>
        <!-- 数字博物馆 -->
        <div>
          <img @click="ShuZibwg()" src="../../assets/Public/Navigation_Buttons/SZBWG.png" alt="" />
        </div>
      </div>
    </div>
    <div class="BottomNav">
      <div class="BottomNavBox">
        <div class="BottomNavMinBox">
          <div style="text-align:center ;"></div>
        </div>
        <div class="BottomNavMinBox">
          <div class="BottomNavMinBoxImg" @click="Login()">
            <img src="../../assets/Public/Navigation_Buttons/RegLogin.png" alt="" />
          </div>
          <div style="text-align:center ; color: white; font-size:12px;">
            登录/注册
          </div>
        </div>
        <div class="BottomNavMinBox" @click="ToMyCollection()">
          <div class="BottomNavMinBoxImg">
            <img src="../../assets/Public/Navigation_Buttons/Collect.png" alt="" />
          </div>
          <div style="text-align:center; color: white; font-size:12px;">
            我的收藏
          </div>
        </div>
        <div class="BottomNavMinBox" @click="ToMyLike()">
          <div class="BottomNavMinBoxImg">
            <img src="../../assets/Public/Navigation_Buttons/Like.png" alt="" />
          </div>
          <div style="text-align:center ; color: white; font-size:12px;">
            我的点赞
          </div>
        </div>
        <div class="BottomNavMinBox" @click="ToShoppingCart()">
          <div class="BottomNavMinBoxImg">
            <img src="../../assets/Public/Navigation_Buttons/Shopping_Trolley.png" alt="" />
          </div>
          <div style="text-align:center ; color: white; font-size:12px;">
            购物车
          </div>
        </div>
        <div class="BottomNavMinBox" @click="ToWritingCenter()">
          <div class="BottomNavMinBoxImg">
            <img src="../../assets/Public/Navigation_Buttons/Creation.png" alt="" />
          </div>
          <div style="text-align:center ; color: white; font-size:12px;">
            我的创作
          </div>
        </div>
      </div>
    </div>
    <img class="fz" @click="Back()" @mouseenter="yiru()" src="../../assets/Public/Navigation_Buttons/BackLogo.png"
      alt="" />
  </div>
</template>

<script>
import $ from "jquery";
export default {
  methods: {
    DHyichu() {
      $(".DaDaoHan").stop(true, true).animate({ width: "0px" }, 200);
      $(".BottomNav").stop(true, true).animate({ height: "0px" }, 200);
      $(".DaDaoHan").hide(100);
      $(".BottomNav").hide(100);
      setTimeout(function () { $('.AnNiuDaoHang').css('background-color', '#000') }, 200)
    },
    yiru() {
      $('.AnNiuDaoHang').css('background-color', 'rgba(255,255,255,0.1)');
      $(".DaDaoHan").show();
      $(".BottomNav").show();
      $('.DaDaoHan').stop(true, true).animate({ width: '388px' }, 420)
      $('.BottomNav').stop(true, true).animate({ height: '450px' }, 410)
    },
    VR() {
      this.$router.push('/vrVisit')
    },
    ShuZiWC() {
      this.$router.push('/culturalCreation')
    },
    SanWeiGZ() {
      this.$router.push('/ancientTown')
    },
    CWGuZhen() {
      this.$router.push('')
    },
    ShuZibwg() {
      this.$router.push('/digtalMuseum')
    },


    Login() {
      this.$router.push('/personalCenter')
    },
    PersonalCenter() {
      this.$router.push('/personalCenter')
    },
    Back() {
      this.$router.push({ path: "/" });
    },
    ToMyCollection() {
      this.$router.push({
        path: "/personalCenter/personalcenter_workbench/mycollection",
      });
    },
    ToMyLike() {
      this.$router.push({
        path: "/personalCenter/personalcenter_workbench/mylike",
      });
    },
    ToWritingCenter() {
      this.$router.push({
        path: "/personalCenter/personalcenter_workbench/writingcenter",
      });
    },
    ToShoppingCart() {
      this.$router.push({
        path: "/personalCenter/ShoppingCart",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.AnNiuDaoHang {
  z-index: 999;
  width: 55px;
  height: 55px;
  position: fixed;
  // left: 96%;
  top: 2px;
  right: 0.3%;
  border-radius: 50%;
  background-color: #000000;
  // opacity: 0.5;
  // 上下左右居中
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 25px;

  .fz {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1);
    display: block;
    cursor: pointer;
  }

  .DaDaoHan {
    backdrop-filter: blur(15px);
    background-color: rgba(84, 84, 84, 0.5);
    width: 0px;
    height: 70px;
    right: 62.5px;
    position: absolute;
    border-bottom-left-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;

    .DaDaoHanBox {
      height: 100%;
      width: 85%;
      margin-left: 8%;
      display: flex;

      div {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-top: 10px;
          transform: scale(0.6);
          cursor: pointer;
        }
      }
    }
  }

  .BottomNav {
    backdrop-filter: blur(15px);
    width: 70px;
    height: 0px;
    background-color: rgba(84, 84, 84, 0.5);
    top: -5px;
    border-bottom-left-radius: 50px;
    position: absolute;
    display: none;

    .BottomNavBox {
      width: 80%;
      height: 95%;
      margin: 0 auto;

      .BottomNavMinBox {
        height: 16.6%;
        width: 100%;

        .BottomNavMinBoxImg {
          height: 65%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            cursor: pointer;
            transform: scale(0.8);
          }
        }
      }
    }
  }
}
</style>
