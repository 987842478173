<template>
    <!-- 底栏 -->
    <div class="DILan">
        <!-- 左边 -->
        <div class="DILan-left">
            <!-- 访问量 -->
            <div class="DILan-left-traffic">
                网站访问量 : 1 &nbsp; 0 &nbsp; 2 &nbsp; 3 &nbsp; 3
            </div>
            <!-- LOGO -->
            <div class="DILan-left-Logo">
                <img src="../../assets\Public\Bottom_Bar/Figure_hx.png" alt="">
            </div>
            <!-- 联系方式 -->
            <div class="DILan-left-contact">
                <img src="../../assets\Public\Bottom_Bar/MicroBlog.png" alt="">
                <img src="../../assets\Public\Bottom_Bar/WeChat.png" alt="">
                <img src="../../assets\Public\Bottom_Bar/Chitchat.png" alt="">
            </div>

            <!-- 其他 -->
            <div class="DILan-left-ohter">
                版权声明 | 技术支持 | 友情链接 | 其他资源
            </div>
        </div>

        <!-- 中间二维码 -->
        <div class="DILan-middle">
            <div class="DILan-middle-one">
                <img src="../../assets/Public/Bottom_Bar/One_QRcode.png" alt="">
                <div>湘江古镇群数字文物资源库</div>
                <div class="beian">湘CP备2022020100号-1</div>
            </div>

            <div class="DILan-middle-one">
                <img src="../../assets/Public/Bottom_Bar/Two_QRcode.png" alt="">
                <div>湘江古镇群全景导览</div>
            </div>

            <div class="DILan-middle-one">
                <img src="../../assets/Public/Bottom_Bar/There_QRcode.png" alt="">
                <div>湘江古镇虚游小程序</div>
            </div>

        </div>

        <!-- 右边友链 -->
        <div class="DILan-right">
            <!-- 标题 -->
            <div class="DILan-right-title">
                <span style="color:#cecece;font-size:18px">友情链接 </span>
                <span style="color:#707070;font-size: 18px;"> / ExchangeLink</span>
            </div>

            <!-- 各项友链 -->
            <div class="DILan-right-link">
                <!-- 第一行 -->
                <div class="link-onehang">
                    <div>
                        <div>&nbsp;<a href="https://zh.unesco.org/" target="_blank"> 联合国教育科学及文化组织</a> </div>
                        <div class="underline" style="width:250px;"></div>
                    </div>

                    <div>
                        <div>&nbsp;<a href="http://www.555edu.com/school-2721-1-108/260484.html" target="_blank">
                                HIST长沙工作站</a></div>
                        <div class="underline" style="width:156px;"></div>
                    </div>
                    <div class="up">
                        <a href="javascript:scroll(0,0)"></a>
                    </div>
                </div>
                <!-- 第二行 -->
                <div class="link-onehang">
                    <div>
                        <div>&nbsp;<a href="http://xczx.ccsu.cn/" target="_blank"> 长沙学院乡村振兴研究院</a> </div>
                        <div class="underline" style="width:231px;"></div>
                    </div>

                    <div>
                        <div>&nbsp;<a href="http://www.unesco-hist.org/index.php?r=article/index&cid=252" target="_blank">
                                HIST衡阳分中心</a></div>
                        <div class="underline" style="width:156px;"></div>
                    </div>
                </div>
                <!-- 第三行 -->
                <div class="link-onehang">
                    <div>
                        <div>&nbsp;<a href="http://www.ccsu.cn/" target="_blank"> 长沙学院</a></div>
                        <div class="underline" style="width:90px;"></div>
                    </div>

                    <div>
                        <div>&nbsp;<a
                                href="https://www.tspweb.com/key/%E4%B8%9C%E5%8D%8E%E5%A4%A7%E5%AD%A6%E9%95%9C%E6%9C%88%E6%B9%96.html"
                                target="_blank"> 月湖论坛</a></div>
                        <div class="underline" style="width:90px;"></div>
                    </div>
                </div>


            </div>
            <!-- 联系我们 -->
            <div class="contact">
                <div class="contact-title">
                    <span style="color:#cecece;font-size:18px">联系我们 </span>
                    <span style="color:#707070;font-size: 18px;"> / Contact Us</span>
                </div>
                <!-- 联系地址 -->
                <div class="contact-address">
                    通信地址：湖南省长沙市开福区洪山路98号长沙学院
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    methods: {
        HDDB() {
            document.documentElement.scrollTop = 1
        },
        XCZXYJY() {
            window.open('http://xczx.ccsu.cn/');
        },
        CSXY() {
            window.open('http://www.ccsu.cn/');
        },
        LHG() {
            window.open('https://www.unesco.org/en/');
        },

    }
}
</script>

<style lang="less" scoped>
@import url(./Bottom_Bar.less);
</style>